import $ from 'jquery';

class AnalyticsEventTracker {
  setEventDimensions(analyticsOpts) {
    // dimension1 = Org Name
    // dimension2 = Buyer
    // dimension3 = Seller
    // dimension4 = State

    if (typeof gtag !== 'undefined') {
      gtag('set', 'dimension1', analyticsOpts.orgname);
      gtag('set', 'dimension2', analyticsOpts.buyer);
      gtag('set', 'dimension3', analyticsOpts.seller);
    }
  }

  trackEvent(category, action, opt_label, opt_value, nonInteraction = false) {
    let eventLabel = opt_label || null;
    let eventValue = opt_value || null;

    if (typeof gtag !== 'undefined') {
      gtag('event', action, {
        'event_category': category,
        'event_label': eventLabel,
        'value': eventValue,
        'non_interaction': nonInteraction
      });
    }
  }
}

const analyticsEventTracker = new AnalyticsEventTracker();
export default analyticsEventTracker;


$('.report-download, .download-coa-pdp, .download-product-instructions, .download-product-marketing-materials').on('click', function(e) {
  let analyticsOpts = $(this).data('analytics-options');
  if (analyticsOpts) {
    analyticsEventTracker.setEventDimensions(analyticsOpts);
    analyticsEventTracker.trackEvent(analyticsOpts.category, analyticsOpts.action, analyticsOpts.label, analyticsOpts.value, true);
  }
});
